.car-filter-modal .modal-wrapper {
    margin: 40px;
    height: 530px;
    border-radius: 5px;
}

.car-filter-modal {
    ion-backdrop {
        --backdrop-opacity: 0.4;
    }
}