@import "../../App";

.login-img-row {
    width: 50%;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
}

.login-form-row {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.send-button {
    --background: #9e100e;
    margin-top: 30px;
    color: white;
}

.item-form {
    --highlight-color-focused: #9e100e;
}

ion-input {
    --ion-placeholder-color: white;
}

.ion-icon-form {
    color: $accent-color;
}

.login-logo {
    height: 20vh !important;
    width: auto !important;
    align-items: center;
    margin-bottom: 5vh;
}