.form-check-item .form-check-conditions {
    padding-left: 10px;
    font-size: 12px;
}

// @media (max-width: 768px) {
//     .car-share-modal .modal-wrapper {
//         margin: 40px;
//         height: 200px;
//     }
// }

.car-share-modal .modal-wrapper {
    margin: 40px;
    height: 425px;
    border-radius: 5px;
}

.car-share-modal {
    ion-backdrop {
        --backdrop-opacity: 0.4;
    }
}