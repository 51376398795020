@import '../../App.scss';

.car-description-item .car-description {
    margin-bottom: 15px;
}

@media (min-width: 768px) {
    .car-detail-grid {
        margin: 40px;
    }
}

@media (max-width: 576px) {
    .car-detail-grid {
        margin: 0px;
        padding: 0px;
    }
}

@media (min-width: 576px) {
    .car-detail-box-info {
        margin-left: 25px;
    }
}

@media (max-width: 768px) {
    .car-detail-second-box-buttons {
        margin-top: 10px;
    }
}

@media (min-width: 768px) {
    .car-detail-second-box-buttons {
        padding: 0px 45px 0px 50px;
        margin: auto;
    }
}

@media (max-width: 768px) {
    .car-auth-form {
        margin: 20px;
    }
}

@media (min-width: 768px) {
    .car-auth-form {
        margin-left: 40px;
        border-radius: 15px;
    }
}

.car-detail-name {
    padding: 0px;
    font-size: 3vh;
}

.car-detail-price {
    padding: 0px;
    font-weight: bold;
    font-size: 3vh;
}

.car-detail-second-box {
    margin-top: 10px;
}

.car-detail-table-content {
    border-radius: 10px;
    background-color: #f6f6f6;
}

.car-detail-table-titles {
    color: $accent-color;
    font-size: 19px;
}

.car-detail-table-values {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.car-detail-table-separator {
    border-left: 1px solid #ccc;
    margin: 25px 15px 25px 0px;
}

ion-item {
    --background: #f6f6f6;
}

ion-toggle {
    margin-left: 50%;
    --background-checked: #f69090;
    --handle-background: #BC2C2C;
}