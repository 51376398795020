ion-toolbar{
    --background: #494949;
    --color: white;
    margin: auto;
    box-shadow: 0px;
}

.toolbar-logo {
    height: 90px !important;
    align-items: center;
}

ion-fab .car-filter-fab {
    margin-right: 15px;
}

.logout-button {
    padding-right: 15px;
}